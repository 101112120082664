import React, { Component, Fragment } from 'react';
import { graphql } from 'gatsby';

// Components
import SeoDetails from '../components/common/seoContent';
import NoHero from '../components/global/noHero';
import StoriesIndex from '../components/templates/storyTags';
import Categories from '../components/global/storyCategories';

// Context
import ContextConsumer from '../components/common/contextProvider';

const StoriesTagTemplate = ({ data, pageContext }) => (
  <ContextConsumer>
    {({ context }) => (
      <Content context={context} data={data} pageContext={pageContext} />
    )}
  </ContextConsumer>
);

class Content extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { data, pageContext } = this.props;
    const { seoContent, stories } = data.contentfulStoryTags;
    const currentSlug = pageContext.slug;
    return (
      <Fragment>
        <SeoDetails seoContent={seoContent} />
        <NoHero type="storyTags" seoContent={seoContent} />
        <Categories currentSlug={currentSlug} />
        <StoriesIndex pageContext={pageContext} stories={stories} />
      </Fragment>
    );
  }
}

export const query = graphql`
  query($id: String!) {
    contentfulStoryTags(id: { eq: $id }) {
      id
      title
      seoContent {
        pageTitle
        menuTitle
        slug
        description {
          description
        }
        featuredImage {
          title
          description
          fluid(maxWidth: 2000) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
      stories {
        id
        title
        slug
        text {
          childMarkdownRemark {
            html
            excerpt(pruneLength: 60)
          }
        }
        featuredImage {
          title
          fluid(maxWidth: 600) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        categories {
          id
          seoContent {
            menuTitle
            slug
          }
        }
        author
        datePosted
      }
      menuOrder
    }
  }
`;

export default StoriesTagTemplate;
