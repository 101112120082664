import React, { Fragment } from 'react';
import orderBy from 'lodash/orderBy';
import moment from 'moment';

// Components
import { ContentContainer, StoriesGrid, StoryCard, Pagination } from './files';

const StoryTagsIndex = ({ stories, pageContext }) => {
  const posts = orderBy(
    stories,
    // eslint-disable-next-line
    [object => new moment(object.datePosted)],
    ['desc']
  );

  const skip = pageContext.skip;
  const limit = pageContext.limit;
  const currentPage = pageContext.currentPage;

  return (
    <Fragment>
      <ContentContainer>
        <StoriesGrid>
          {posts.slice(skip, limit * currentPage).map(post => (
            <StoryCard key={post.id} {...post} />
          ))}
        </StoriesGrid>
      </ContentContainer>
      <Pagination context={pageContext} />
    </Fragment>
  );
};

export default StoryTagsIndex;
